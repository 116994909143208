<template>
    <div v-bind="$attrs">

        <div class="flex flex-col gap-4 items-start">

            <!-- Demo organization projects -->

            <template v-if="demoOrganization">
                <h2>Projets démo</h2>

                <div v-if="canWrite(demoOrganization)" class="admin-ui screenshot-hidden">
                    <Button label="Ajouter un projet démo"
                            @click="createDemoProject"
                            class="p-button-sm p-button-outlined p-button-info"></Button>
                </div>

                <DataGridComponent class="w-full mb-4">
                    <ProjectsListItem v-for="project in demoOrganization.projects"
                                      :key="project.id" :project="project"
                                      @duplicate="duplicateProject"
                                      @duplicateToPersonal="duplicateToPersonal"
                                      @delete="deleteProject"/>
                </DataGridComponent>


            </template>


            <h2>Mes projets</h2>

            <!-- Add project button  -->

            <Button icon="pi pi-plus"
                    v-if="canWrite(personalOrganization)"
                    :label="$t('projectsList.addProjectButtonLabel')"
                    class="p-button-sm p-button-outlined align-self-start screenshot-hidden"
                    @click="createProject"
                    :disabled="!canWrite(personalOrganization)"
            />


            <InformationBox v-if="isUnsub" icon="InformationCircleIcon"
                            color="green"
                            class="max-w-prose">
                <i18n-t scope="global" keypath="projectsList.unsubInfo">

                </i18n-t>

            </InformationBox>

            <DataGridComponent class="w-full">

                <InformationBox v-if="!isUnsub && userProjects.length === 0" icon="exclamation-circle" color="green">

                    <i18n-t scope="global" keypath="projectsList.empty">
                        <span class="inline-block font-semibold">
                            {{ $t('projectsList.addProjectButtonLabel') }}
                        </span>
                        <span class="pi pi-ellipsis-v align-middle"></span>
                    </i18n-t>

                </InformationBox>


                <template v-if="ready">
                    <ProjectsListItem v-for="project in userProjects" :key="project.id"
                                      :project="project"
                                      @duplicate="duplicateProject"
                                      @delete="deleteProject"
                    />
                </template>


            </DataGridComponent>

        </div>

    </div>

    <ProjectEditionDialog ref="projectEditionDialog"/>

    <CloneDialogForm ref="cloneDialog" @cancel="cancelCloning" @save="saveCloning"/>

</template>

<script>
import {mapActions} from "vuex";
import ProjectEditionDialog from "@/components/project/rework/dialogs/ProjectEditionDialog";
import CloneDialogForm from "@/components/ui/CloneDialogForm";
import ProjectsListItem from "@/components/project/rework/ProjectsListItem";
import projectEdition from "@/mixins/projectEdition";
import InformationBox from "../../ui/InformationBox";
import DataGridComponent from "../../ui/DataGridComponent";

export default {
    name: "ProjectList",
    props: {
        projects: Array,
        organizations: Array
    },

    components: {
        DataGridComponent,
        InformationBox,
        ProjectsListItem,
        ProjectEditionDialog,
        CloneDialogForm,
    },

    mixins: [projectEdition],

    computed: {
        ready() {
            return this.projects || false
        },
        demoOrganization() {
            return this.organizations.find(x => x.demo)
        },
        userOrganizations() {
            return this.organizations.filter(x => !x.demo)
        },
        personalOrganization() {
            return this.organizations.find(x => x.personal)
        },
        userProjects() {
            return this.organizations.filter(x => !x.demo).flatMap(x => x.projects)
        }

    },

    methods: {
        ...mapActions('project', ['fetchAllProjects', 'cloneProject']),

        createProject() {
            if (!this.canWrite(this.personalOrganization))
                return
            this.$refs.projectEditionDialog.open()
        },

        createDemoProject(){
            if (this.canWrite(this.demoOrganization)){
                this.$refs.projectEditionDialog.open({organizationId: this.demoOrganization.id})
            }
        },

        duplicateProject(payload) {
            this.$refs.cloneDialog.open(payload)
        },

        async saveCloning(payload) {
            this.$refs.cloneDialog.loading = true
            await this.cloneProject(payload)
                .then(() => this.notifySuccess({detail: 'projet dupliqué'}))
                .catch(err => {
                    this.notifyError({detail: 'échec duplication'})
                    console.error(err)
                })
            this.$refs.cloneDialog.close()
        },

        async duplicateToPersonal(payload) {
            console.log("duplicate to personal", payload)
            payload.toPersonalOrga = true
            await this.saveCloning(payload)
        },

        cancelCloning() {
            this.$refs.cloneDialog.close()
        },

        deleteProject(payload) {
            this.confirmDeleteProject(payload)
        }
    }
}
</script>

<style scoped>

</style>